import { getAuth } from "firebase/auth";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from './etc/Firebase'
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom";
import { doc, getDocs,getDoc, addDoc, getFirestore, updateDoc, query, where, collection, documentId } from "firebase/firestore";
import { useEffect, useState } from "react";
import ReportesComponent from "./components/ReportesComponent";

import "./index.scss"
import { GetCreativeUrls } from "./etc/CreativeHelpers";

// const q = query(docRef, where("readwhitelist", "array-contains", cookies.get("email")));


export default function ActualApp() {
    const cookies = new Cookies()
    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);
    const navigate = useNavigate();
    const auth = getAuth();

    let [reportes, setReportes] = useState(null)
    let [role, setRole] = useState(null);

    function signtfOut() {
        auth.signOut().then(function () {
            cookies.remove("id_token")
            cookies.remove("loggedIn")
            cookies.remove("brand_permissions")
            window.location.reload()
        }).catch(function (error) {
            console.error(error)
        })
    }

    async function getData() {
        if (!auth.currentUser.email) {
            signtfOut()
        }
        const userRef = doc(db, "report_usuarios", auth.currentUser.email);

        let userData = await getDoc(userRef);
        userData = userData.data()

        setRole(userData.role)
        cookies.set("role", userData.role);
        const docRef = collection(db, "report");
        
        if (userData.role === "user") {
            if (!userData.brands) {alert("El usuario no tiene acceso a ninguna marca.")}
            cookies.set("brand_permissions", userData.brands);
            const brand_keys = Object.keys(userData.brands).filter(k=>userData.brands[k])
            if (brand_keys.length===0) {alert("El usuario no tiene acceso a ninguna marca."); signtfOut()}
            // console.log(brand_keys)
            const q = query(docRef, where(documentId(), "in", brand_keys));
            const querySnapshot = await getDocs(q);
            let docs = []
            querySnapshot.forEach((doc) => {
                docs.push({...doc.data(), id: doc.id})
            });
            // console.log(docs)
            if (docs.length === 1) {
                navigate("/brand/" + docs[0].id)
            }
    
            return docs
    
        } else if (userData.role === "admin") {
            const querySnapshot = await getDocs(docRef);
            let docs = []
            querySnapshot.forEach((doc) => {
                docs.push({...doc.data(), id: doc.id})
            });
    
            return docs
    
        } else {
            alert("El rol " + userData.role + " es inválido, por favor contactarse con los administradores")
        }
        
    }


    async function addNewBrand(data) {
        await addDoc(collection(db, "report"), data);
        updateData();
    }
    useEffect(() => {
        updateData()
    }, [])
    function updateData() {
        getData().then((data) => {
            setReportes(data);
        })

    }
    return (
        <>
            {
            reportes
            ? <ReportesComponent data={reportes} addNewBrand={addNewBrand} role={role}/>
            : null
            }
        </>
    )
}