import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from '../etc/Firebase'
import { doc, getDoc, getDocs, getFirestore, updateDoc, collection } from "firebase/firestore";
import Spinner from "../etc/Spinner";
import { getAuth, updatePassword } from "firebase/auth";

export default function Usuario() {
    let [password, setPassword] = useState("");
    let [data, setData] = useState(false);
    let [loading, setLoading] = useState(true);
    let [btnEnabled, setBtnEnabled] = useState(true)

    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);
    let params = useParams();
    let navigate = useNavigate();
    let auth = getAuth();
    // let usuario = params.usuario;
    let usuario = auth.currentUser.email;

    function signtfOut() {
        auth.signOut().then(function () {
            window.location.reload()
        }).catch(function (error) {
            console.error(error)
        })
    }


    const getData = async (_brand) => {
        const ownUserRef = doc(db, "report_usuarios", auth.currentUser.email);
        const userRef = doc(db, "report_usuarios", usuario);
        const reportsRef = collection(db, "report");

        let _ownUserData = await getDoc(ownUserRef);
        let ownUserData = _ownUserData.data();
        let isOwnEdit = (usuario === _ownUserData.id)
        let _ownrole = ownUserData.role;
        if (_ownrole !== "admin" && !isOwnEdit) { navigate("/") }
        if (_ownrole === "admin") {
            if (!(await getDoc(userRef)).exists()) { alert("El usuario " + usuario + " no existe.") }
            let userData = (await getDoc(userRef)).data();
            let _userRole = userData.role;
            if (_userRole === "admin") return { _ownrole: _ownrole, _userRole: _userRole, isOwnEdit: isOwnEdit }
            let permissions = userData.brands;
            if (!permissions) { permissions = {} }
            const querySnapshot = await getDocs(reportsRef);

            querySnapshot.forEach((doc) => {
                doc = doc.data();
                if (!(doc.name in permissions)) {
                    permissions[doc.name] = false;
                }
            });
            return { _ownrole: _ownrole, _userRole: _userRole, _permissions: permissions, isOwnEdit: isOwnEdit };

        }
        for (let marca in ownUserData.brands) {
            if (ownUserData.brands[marca]) {
                let data = (await getDoc(doc(db, "report", marca)));
                let _data = data.data();
                console.log(_data.name)
                return { _ownrole: _ownrole, all_data: ownUserData, marca: {..._data, fs_id: data.id} };

            }
        }

        alert("?");
    }
    useEffect(() => {
        getData().then(data => {
            setData(data);
            setLoading(false);
        })
    }, [])

    async function updateDataInFS(marca, nuevadata) {
        let newdata = {}
        for (let mk in nuevadata) {
            if (nuevadata[mk] !== "false" && nuevadata[mk] !== false) {
                newdata[mk] = nuevadata[mk];
            }
        }
        await updateDoc(doc(db, "report_usuarios", usuario), { brands: newdata });
    }

    function changePermissionsInAdminView(e, marca) {
        let __data = { ...data };
        __data._permissions[marca] = e.target.value;
        setLoading(true);
        updateDataInFS(marca, __data._permissions).then(() => {
            setData(__data);
            setLoading(false);
        })
    }

    function changeRole(__newRole) {
        async function changeRoleInFS(__newRole) {
            await updateDoc(doc(db, "report_usuarios", usuario), { role: __newRole });
        }
        let __data = { ...data };
        __data._userRole = __newRole;
        setLoading(true);
        changeRoleInFS(__newRole).then(() => {
            setData(__data);
            setLoading(false);
        })
    }

    function cambiamelacontraseñamauro(e) {
        e.preventDefault()
        setBtnEnabled(false);
        updatePassword(auth.currentUser, password).then(() => {
            console.log("ok!", password);
            alert("Contraseña cambiada con éxito")
            setBtnEnabled(true);
        }).catch((err) => {
            if (err.message === "auth/requires-recent-login") {
                alert("Por medidas de seguridad, es necesario tener un login reciente para " +
                      "cambiar la contraseña. Por favor, cerrar sesión y volver a loguearse.")
            }
            else {
                alert(err.message)
            }
            setBtnEnabled(true);
        })
    }
    return (
        <div className="usuario__root">
            {
                !loading ? (
                    <div className="usuario__container">
                        <div className="usuario__header">

                            {data.isOwnEdit || data._ownrole === "user" ? (
                                <h2>
                                    Tu usuario
                                </h2>
                            ) : usuario}

                        </div>

                        <div className="usuario__box">
                            {
                                    <UserView usuario={usuario} data={data} passchange={cambiamelacontraseñamauro} password={password} 
                                    setPassword={setPassword} btnEnabled={btnEnabled} cerrar_sesion={signtfOut} />
                            }
                            {/* 
                            <div className="box_user" id="box_permisos">
                                {renderPermisos()}
                            </div>
                            <div className="box_user" id="box_rol">
                                {data.role}
                            </div> 
                    */}
                        </div>
                    </div>

                ) : <Spinner />
            }
        </div>
    )
}

export function UserView({ data, passchange, password, setPassword, btnEnabled, usuario, cerrar_sesion }) {
    let marca = data._ownrole === "admin" ? "Admin" : data.marca.name;
    let marca_o_rol = data._ownrole === "admin" ? "Rol:" : "Marca:"
    return (
        <div className="usuario__userview" >
            <div className="usuario__marca">
                <div className="usuario__marca_title">
                    {marca_o_rol}
                </div>
                {marca}
            </div>
            <div className="usuario__email">
                <div className="usuario__email_title">
                    Dirección de correo:
                </div>
                <div className="usuario__email_data">
                    {usuario}
                </div>
            </div>
            <div className="usuario__pass">
                <div className="usuario__pass_title">
                    Cambiar contraseña:
                </div>
                <div className="usuario__pass_data">
                    <form onSubmit={passchange} className="usuario__form">
                        <input className="usuario__pass_data_input_text" type="password" placeholder="Nueva contraseña..." value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        <div className="usuario__pass_data_input_div">
                            <input type="submit" className="usuario__pass_data_input_submit" disabled={!btnEnabled} value="Guardar"></input>
                        </div>
                    </form>
                </div>
            </div>
            <div className="usuario__cerrar_sesión">
                <p onClick={cerrar_sesion}>Cerrar sesión</p>
            </div>
        </div>
    )
}

export function AdminView({ data, changeData, changeRole }) {

    let a = data._userRole !== "admin" ? (
        <table>
            <thead>
                <tr>
                    <th>Marca</th>
                    <th>Permiso</th>
                </tr>

            </thead>
            <tbody>


                {Object.keys(data._permissions).map((line) => {
                    return (
                        <tr key={line}>
                            <td>{line}</td>
                            <td>

                                <select name="perm" id="perm" value={data._permissions[line]}
                                    onChange={(e) => changeData(e, line)}>
                                    <option value="r">Lectura</option>
                                    <option value="w">Escritura</option>
                                    <option value={false}>No</option>
                                </select>


                            </td>

                        </tr>
                    )
                })}
            </tbody>

        </table>
    ) : null

    let b = (
        <>
            {data._userRole !== "admin" ? (
                <select name="role" id="role" value={data._userRole}
                    onChange={(e) => changeRole(e.target.value)}>
                    <option value="user">Usuario</option>
                    <option value="admin">Administrador</option>
                </select>

            ) : (
                <p>Rol: {data._userRole}</p>
            )}
        </>
    )

    return (
        <>
            {data._userRole !== "admin" ? (
                <div className="box_user" id="box_permisos">
                    {a}
                </div>
            ) : null
            }
            <div className="box_user" id="box_rol">
                {b}
            </div>
        </>
    )
}