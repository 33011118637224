import { createContext, useState, useEffect } from "react";


const ThemeContext = createContext()
const ThemeProvider = ({ children }) => {
const [theme, setTheme] = useState('#ebebeb');


// useEffect(() => {
//   console.log(theme);
// }, [theme]);


const changeColor =(c) => setTheme(c)

    return (<ThemeContext.Provider value={{theme, setTheme, changeColor}}>{children}</ThemeContext.Provider>)
}

export { ThemeProvider }
export default ThemeContext