import React from "react";
import { getFirestore } from 'firebase/firestore';
import { useFirebaseApp, FirestoreProvider } from 'reactfire';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ActualApp from "../ActualApp";
import SingleBrand from "../components/SingleBrand";
import SingleReport from "../components/SingleReport";
import Users from "../components/Users";
import SingleBrandConfig from "../components/SingleBrandConfig";
import NotFound from "../components/NotFound";
import { ThemeProvider } from "../Context/ThemeContext";
import Usuario from "../components/Usuario";
import ReportesConfig from "../components/ReportesConfig";
import CambiarContraseña from "../components/CambiarContraseña";
import Admin from "../components/Admin";

export default function Rutas() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <ThemeProvider>
        <Router>
          {/* <Nav /> */}
          <Routes>

            <Route exact path="/" element={<ActualApp />} />
            <Route exact path="/brands" element={<ActualApp />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/yo" element={<Usuario />} />
            <Route exact path="/users/:usuario" element={<Admin />} />
            <Route exact path="/brand/:id/config_reportes" element={<ReportesConfig />} />
            <Route exact path="/brand/:id/:seccion" element={<SingleReport />} />
            <Route exact path="/brand/:id" element={<SingleBrand />} />
            <Route exact path="/brand_config/:id" element={<SingleBrandConfig />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </FirestoreProvider>

  );
}
