import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from "universal-cookie";
import Rutas from "./Rutas";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";

// const AuthenticationContext = createContext()

import { useEffect, useState } from 'react';
import Spinner from "./Spinner";
import CambiarContraseña from "../components/CambiarContraseña";


const useAuthListener = () => {



    // assume user to be logged out
    const [loggedIn, setLoggedIn] = useState(false);
    const cookies = new Cookies()

    // keep track to display a spinner while auth status is being checked
    const [checkingStatus, setCheckingStatus] = useState(true);
    const auth = getAuth()

    useEffect(() => {
        // auth listener to keep track of user signing in and out
        onAuthStateChanged(auth, (user) => {

            if (user) {

                setLoggedIn(true);

                const data = {
                    "email": user.email,
                    "name": user.displayName,
                    "photo": user.photoURL,
                    "uid": user.uid,
                    "role": "",
                    "createdAt": user.metadata.creationTime,
                    "lastLogin": user.metadata.lastSignInTime
                }

                cookies.set("displayName", data.name)
                cookies.set("photoUrl", data.photo)
                cookies.set("loggedIn", true, { path: '/' })


                user.getIdToken().then(function (idToken) {
                    cookies.set("id_token", idToken, { path: '/' })
                }).catch(function (error) {
                    console.log(error)
                });
            }
            setCheckingStatus(false);
        });
    }, []);
    return { loggedIn, checkingStatus };
};


export default function IsLoggedIn() {
    const { loggedIn, checkingStatus } = useAuthListener();
    //     useEffect(() => {
    // console.log(theme);
    //     }, [theme]);
    return (
        <>
            {/* {   loggedIn ? 
                    <Nav /> 
                : null
            } */}
            {/* <div className="display" style={{ backgroundColor: `${theme}` }} id="display"> */}
            <div className="display_container" id="display_container">

                {checkingStatus
                    ? <Spinner />
                    : loggedIn

                        // if user is logged in, grant the access to the components
                        ? (
                            <>
                                <Rutas />
                            </>

                        )

                        // else render login page
                        : (
                            <Router>
                                <Routes>
                                    <Route exact path="/cambiar_pass" element={<CambiarContraseña />} />
                                    <Route path="*" element={<LoginPage />} />
                                </Routes>

                            </Router>
                        )
                }
            </div>

        </>
    )
};

