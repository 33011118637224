import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Cookies from "universal-cookie"
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import ThemeContext from "../Context/ThemeContext";
import IMAGES from "../assets";
import CustomImg from '../components/Imgs/customImg'
import { useFirebaseApp } from "reactfire";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "./Firebase";
import Spinner from "./Spinner";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"



function LoginPage(props) {
  const cookies = new Cookies()
  const navigate = useNavigate();
  let [emailState, setEmail] = useState("");
  let [passwordState, setPassword] = useState("");
  let [passwordVisible, setPasswordVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext)

  const firebase = useFirebaseApp(firebaseConfig)
  const functions = getFunctions(firebase);
  // connectFunctionsEmulator(functions, "localhost", "5001")
  const resetPassword = httpsCallable(functions, 'resetPassword');

  const auth = getAuth();

  function signInWithEmail(e) {
    e.preventDefault()
    signInWithEmailAndPassword(auth, emailState, passwordState)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        var data = {
          "email": user.email,
          "role": "",
          "createdAt": user.metadata.creationTime,
          "lastLogin": user.metadata.lastSignInTime
        }

        user.getIdToken().then(function (idToken) {
          cookies.set("id_token", idToken, { path: '/' })
        }).catch(function (error) {
          console.error(error)
        });

        cookies.set("email", data.email)
        cookies.set("loggedIn", true)

        navigate("/brands");

      })
      .catch((error) => {
        console.log(error.code)

        if (error.code === "auth/wrong-password") {
          alert("Contraseña incorrecta")
        }

        else if (error.code === "auth/user-not-found") {
          alert("Usuario no encontrado, contactarse con administrador")
        }
      });

  }
  async function reestablecer_pass() {
    if (emailState === "") {alert("Ingresar email"); return;}
    setLoading(true);
    let a = await resetPassword({email_a_resetear: emailState});
    setLoading(false);

    if (a.data.response !== "ok") {
      alert("Error cambiando la contraseña, contactarse con administradores. Error: " + JSON.stringify(a));
    }
    else {
      alert("Link de reset enviado a " + emailState);
    }

  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };



  return !loading ? (
    <div className="display">

    <div className="logger">
      <div className="image-container">
        <CustomImg {...IMAGES.isologotipoBeyond} clase="" />
      </div>
      <div className="form-container">
        <h1>Inicio de sesión</h1>
        <form onSubmit={signInWithEmail}>
            <input type="text" placeholder="Mail" value={emailState} onChange={(e) => setEmail(e.target.value)} name="mail" />
            <input id="txtPassword" type={passwordVisible ? 'text' : 'password'} placeholder="Password" value={passwordState} onChange={(e) => setPassword(e.target.value)} name="pass" />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="password-toggle"
            >
              {passwordVisible ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
            </button>
            <input type="submit" value="Acceder" className="submit" />
        </form>
        <a className="forgot_pass" onClick={reestablecer_pass}>Olvidé mi contraseña.</a>
      </div>

    </div>
  </div>

  ) : <Spinner />

}

export default LoginPage;