export default function FormasBackground() {
    return (
        <>
            <div className="formas">
                <div className="formasfondo_izq">
                    <div className="forma_back_45">
                    </div>
                    <div className="forma_back_46">
                    </div>
                </div>
                <div className="formasfondo_der">
                    <div className="formas_254">
                    </div>
                    <div className="formas_25">
                    </div>

                </div>
            </div>
        </>
    )
}