import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { creativeRef } from "./Firebase"
import makeid from "./Id";

export function GetCreativeUrls(id) {
    var id = ref(creativeRef, id)
    var url = getDownloadURL(id)
        .then((url) => {
            return url
        })
        .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            console.log(error);
        })
    return url
};

export function UploadFileToStorage(file) {
    const id = makeid(10);
    const fileRef = ref(creativeRef, id);
    uploadBytes(fileRef, file).then((snapshot) => {
        console.log('Uploaded ' + file.name);
    }).catch((err)=> {
        console.log(err)
    })
    return id;
}