import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFirebaseApp } from "reactfire";
import { firebaseConfig } from "../etc/Firebase";
import Spinner from "../etc/Spinner";

export default function CambiarContraseña() {
    let [searchParams, setSearchParams] = useSearchParams();
    let [loading, setLoading] = useState(true);

    let navigate = useNavigate();    
    const firebase = useFirebaseApp(firebaseConfig)
    const functions = getFunctions(firebase);
    const resetPasswordCallback = httpsCallable(functions, 'resetPasswordCallback');
    let token = searchParams.get("token");

    // connectFunctionsEmulator(functions, "localhost", "5001")
    
    let resetWrapper = async () => {
        let a = await resetPasswordCallback({token});
        alert(a.data.response)
        navigate("/")
    }
    useEffect(()=>{
        resetWrapper()
    }, [])

    return !loading ? ("wtf") : <Spinner />
}