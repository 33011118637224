import {initializeApp} from 'firebase/app'
import {getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore"
import 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: "AIzaSyCcczhI1EnvYXNSJg3tQ-qWs67hou1-sJg",
  authDomain: "beyond-report.firebaseapp.com",
  projectId: "beyond-report",
  storageBucket: "beyond-report.appspot.com",
  messagingSenderId: "509534438148",
  appId: "1:509534438148:web:4b3ca7ed24f99536c2c651"
};

const app = initializeApp(firebaseConfig)

export const authentication = getAuth(app)
export const db = getFirestore();
export const storage = getStorage();
export const creativeRef = ref(storage, "beyondreport_files")
export default app
