// a la proxima persona que tenga que leer esto, o a mi del futuro: perdon

import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from '../etc/Firebase'
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, updateDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import ThemeContext from "../Context/ThemeContext";
import { GetCreativeUrls, UploadFileToStorage } from "../etc/CreativeHelpers";
import Cookies from "universal-cookie";
import useScript from "../hooks/useScript";
import Spinner from "../etc/Spinner";
import { getAuth } from "firebase/auth";
export const RE = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;


export default function SingleBrand() {
    // useScript("https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.1/p5.min.js");
    // useScript("/sketch_background.js");

    const navigate = useNavigate();
    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);
    const { theme, setTheme } = useContext(ThemeContext)

    let cookies = new Cookies();
    let params = useParams();
    let id = params.id;
    const auth = getAuth();

    let [displayStyle, setDisplayStyle] = useState(null)
    let [editSeccion, toggleEditSeccion] = useState(false);
    let [seccionEditIndex, setSeccionEditIndex] = useState(null);
    let [editOrderMode, toggleEditOrderMode] = useState(false);
    let [editOrderSeccionesData, setEditOrderSeccionesData] = useState(null);
    let [seccionesLibres, setSeccionesLibres] = useState([]);
    let [brand, setBrand] = useState(null);
    let [secciones, setSecciones] = useState(null)
    let [menuReport, toggleMenuReport] = useState(false);
    let [menuSeccion, toggleMenuSeccion] = useState(false);
    let [newReportName, setNewReportName] = useState("");
    let [newUrlDataStudio, setNewUrlDataStudio] = useState("");
    let [newSeccion, setNewSeccion] = useState("");
    let [newSeccionPhoto, setNewSeccionPhoto] = useState("");
    let [logoUrl, setLogoUrl] = useState(false);
    let [seccionLogoId, setSeccionLogoId] = useState("");
    let [seccionLogoUrls, setSeccionLogoUrls] = useState(null);
    let [loading, setLoading] = useState(true);
    let [buttonDisabled, toggleButtonDisabled] = useState(false);
    // let [permissions, setPermissions] = useState(cookies.get("brand_permissions"));
    let [isWriter, setIsWriter] = useState(false);
    let [isOnlyBrand, setIsOnlyBrand] = useState(true);
    let [accesoSecciones, setAccesoSecciones] = useState(null);
    let [accesoSeccionesLoaded, setAccesoSeccionesLoaded] = useState(false);

    const getUserData = async (_brand) => {
        const userRef = doc(db, "report_usuarios", auth.currentUser.email);
        let userData = await getDoc(userRef);
        userData = userData.data()
        let boo = userData.role === "admin" || (userData.brands[_brand.name] && userData.brands[_brand.name].includes("w")) || (userData.brands[_brand.fs_id] && userData.brands[_brand.fs_id].includes("w"))
        let _isWriter = boo
        let _isOnlyBrand = !(userData.role === "admin") && Object.keys(userData.brands).filter(b=>userData.brands[b]).length === 1;
        // let _secciones = userData.role !== "admin" ? userData.secciones : false;
        let _secciones = userData.role !== "admin" ? (userData.secciones ? userData.secciones[_brand.fs_id] : false) : false;

        return { isWriter: _isWriter, isOnlyBrand: _isOnlyBrand, secciones: _secciones };
    }
    function signtfOut() {
        auth.signOut().then(function () {
            cookies.remove("id_token")
            cookies.remove("loggedIn")
            cookies.remove("brand_permissions")
            window.location.reload()
        }).catch(function (error) {
            console.error(error)
        })
    }


    // const isDudeWriterOrNot = (_brand) => {
    //     try {
    //         setIsWriter( cookies.get("role") === "admin" || permissions[_brand.name].includes("w"));
    //     } catch (err){
    //         console.error(err);
    //     }
    // }

    const newReportMenu = (e, seccion) => {
        e.stopPropagation()
        setNewSeccion(seccion);
        toggleMenuReport(true);
        toggleMenuSeccion(false);
    }
    const newSeccionMenu = (seccion) => {
        setNewSeccion("");
        setNewSeccionPhoto("");
        setSeccionLogoId("");
        toggleMenuReport(false);
        toggleMenuSeccion(true);
    }


    const _addNewReport = (e) => {
        e.preventDefault();
        toggleButtonDisabled(true);
        if (!RE.test(newUrlDataStudio)) {
            toggleButtonDisabled(false)

            alert("Ingrese una URL válida");
            return;
        }

        // for (let item of brand.reportes) {
        //     if (newReportName === item.name) {
        //         toggleButtonDisabled(false)

        //         alert("ya existe un reporte con ese nombre")
        //         return
        //     }
        // }

        let newReport = {
            name: newReportName,
            url_datastudio: newUrlDataStudio,
            seccion: newSeccion.fs_id,
            timestamp: Date.now()
        }

        addNewReport(newReport).then(() => {
            toggleMenuReport(false);
            toggleButtonDisabled(false)
        })
    }
    const _addNewSeccion = (e) => {
        toggleButtonDisabled(true);
        e.preventDefault();

        // for (let item of brand.secciones) {
        //     if (newSeccion === item.name) {
        //         alert("Por favor elegir un nombre de sección único.")
        //         return
        //     }
        // }

        let newSeccionShit = {
            name: newSeccion.name,
            logo_id: seccionLogoId,
        }
        addNewSeccion(newSeccionShit).then(() => {
            toggleMenuSeccion(false);
            toggleButtonDisabled(false);

        })
    }

    async function getData() {
        let marcaRef = doc(db, "report", id);
        let marcaDoc = await getDoc(marcaRef);
        let marcaData = { ...marcaDoc.data(), fs_id: marcaDoc.id }
        let seccionesRef = collection(db, "report", id, "secciones");
        let seccionesData = (await getDocs(seccionesRef)).docs.map(s => ({ ...s.data(), fs_id: s.id }))
        // a.secciones.sort((a, b) => a.name.localeCompare(b.name))
        // a.secciones.sort((a, b) => a.display_order > b.display_order ? 1 : -1)
        // tmb traer reportes?
        // const querySnapshot = await getDocs(collection(db, "report", id, "reportes"));
        // let reportes = []
        // querySnapshot.forEach((doc) => {
        //     reportes.push({...doc.data(), id: doc.id})
        // });
        // brand.reportes = reportes;

        return { marca: marcaData, secciones: seccionesData }
    }

    async function addNewReport(data) {
        await addDoc(collection(db, "report", id, "secciones", newSeccion.fs_id, "reportes"), data);
        updateData();
    }
    async function addNewSeccion(data) {
        await addDoc(collection(db, "report", id, "secciones"), data);
        setNewSeccion("");
        setSeccionLogoId("");
        setNewSeccionPhoto("");
        updateData();
    }

    const fetchLogos = async (logo_id) => {
        const _urls = logo_id ? await GetCreativeUrls(logo_id) : null;
        return _urls
    }
    const lightenColor = function (color, percent) {
        color = color.replace("#", "")
        var num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            B = (num >> 8 & 0x00FF) + amt,
            G = (num & 0x0000FF) + amt;

        let a = "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
        return a
    };

    function getSeccionesLibres(secciones, ids_orden) {
        if (!ids_orden) return []
        let _new = secciones.filter(x => !ids_orden.includes(x.fs_id)).map((s) => s.fs_id);
        return _new;
    }
    function updateData() {
        getData().then((data) => {

            setBrand(data.marca);
            setSecciones(data.secciones);
            setEditOrderSeccionesData(data.marca.orden || data.secciones.map(s => s.fs_id));
            setSeccionesLibres(getSeccionesLibres(data.secciones, data.marca.orden))
            fetchLogosSeccion(data.marca, data.secciones);
            getUserData(data.marca).then((isHEAFUCKINGWRITER) => {
                if (isHEAFUCKINGWRITER.secciones && isHEAFUCKINGWRITER.secciones.length===1) {
                    navigate("/brand/" + data.marca.fs_id + "/" + isHEAFUCKINGWRITER.secciones[0])
                }
                setIsWriter(isHEAFUCKINGWRITER.isWriter);
                setIsOnlyBrand(isHEAFUCKINGWRITER.isOnlyBrand);
                setAccesoSecciones(isHEAFUCKINGWRITER.secciones);
                setAccesoSeccionesLoaded(true);

            })
            let darker = lightenColor(data.marca.color_primario, -70);
            document.getElementById("display_container").style.background = `radial-gradient(${data.marca.color_primario}, ${darker})`
            fetchLogos(data.marca.logo_id).then((_urls) => {
                setLogoUrl(_urls);
                setLoading(false);
            })
            // isDudeWriterOrNot(data);
            // let _secciones = data.reportes.map((e) => e.seccion);
            // setSecciones([...new Set(_secciones)]);
        })

    }

    // useEffect(()=>{
    //     console.log("_libres", seccionesLibres)
    // }, [seccionesLibres])
    // useEffect(()=>{
    //     console.log("_orden", editOrderSeccionesData)
    // }, [editOrderSeccionesData])


    useEffect(() => {
        updateData();

    }, [])

    const hasReports = (seccion) => {
        let a = brand.reportes.some((r) => (r.seccion === seccion.name));
        return a;
        // navigate("/brand/"+id+"/"+r.name)
    }
    const navigateConditional = (seccion) => {
        return navigate("/brand/" + id + "/" + seccion.fs_id)

        if (editOrderMode) return false
        let a = brand.reportes.some((r) => (r.seccion === seccion.name));
        let b = brand.reportes.filter((r) => (r.seccion === seccion.name));
        return a ? navigate("/brand/" + id + "/" + seccion.name + "/" + b[0].name) : false
    }
    const navigateForOnlySeccion = (data, seccion) => {
        let a = data.reportes.some((r) => (r.seccion === seccion));
        let b = data.reportes.filter((r) => (r.seccion === seccion));
        return a ? navigate("/brand/" + id + "/" + seccion + "/" + b[0].name) : false
    }


    // helpers del menu de edicion
    async function saveEdit() {
        async function updateSecciones() {
            await updateDoc(doc(db, "report", id), {
                orden: editOrderSeccionesData
            });
        }
        async function guardarSeccionesLibres() {
            await updateDoc(doc(db, "report", id), {
                seccionesLibres: seccionesLibres
            });
        }

        // let i = 0;
        // for (let seccionNueva of editOrderSeccionesData) {
        //     if (seccionNueva) {
        //         seccionNueva.display_order = i;
        //     }
        //     i++;
        // }
        setLoading(true);
        await guardarSeccionesLibres()
        await updateSecciones()
        updateData()
        toggleEditOrderMode(false)
        setLoading(false)
    }

    // end helpers del menu de edicion


    // helpers edit seccion

    function seccionEdit(e, seccion) {
        console.log(seccion)
        e.stopPropagation();
        setNewSeccion(seccion);
        setSeccionLogoId(seccion.logo_id);
        setSeccionEditIndex(secciones.findIndex(i => i.fs_id === seccion.fs_id));
        toggleEditSeccion(true);
        // console.log("a")
    }
    function submitEditSeccion(e) {
        toggleButtonDisabled(true);
        e.preventDefault();
        let __newSeccion = {
            name: newSeccion.name,
            logo_id: seccionLogoId
        }
        // let __newSecciones = [...brand.secciones]
        // __newSecciones[seccionEditIndex] = __newSeccion;

        // _seccion.name = seccionName;
        // updateBrandInFS(_brand);
        console.log(newSeccion)
        async function updateBrandInFS(data) {
            await updateDoc(doc(db, "report", id, "secciones", newSeccion.fs_id), {
                name: newSeccion.name,
                logo_id: seccionLogoId
            });
        }
        updateBrandInFS().then(() => {
            setNewSeccion("");
            setSeccionLogoId("");
            setSeccionEditIndex(null);
            updateData()
            toggleEditSeccion(false);
            toggleButtonDisabled(false);
        })

    }
    function addFile(e) {
        let id = UploadFileToStorage(e.target.files[0]);
        setSeccionLogoId(id);
    }

    // end helpers edit seccion

    // helpers logos secciones

    const fetchLogosSeccion = (marca, secciones) => {
        const fetchImages = () => {
            // let _urls = {}
            let _urls = []
            let index = []
            for (let d of secciones) {
                if (d.logo_id && d.logo_id !== "") {
                    // console.log(d.name, d.logo_id)
                    let url = GetCreativeUrls(d.logo_id);
                    _urls.push(url);
                    index.push(d.name);
                }
            }
            return { _urls, index }
        }
        const fetchBackground = async (marca) => {
            if (!marca.gradiente && marca.bg_image) {

                return GetCreativeUrls(marca.bg_image).then((url_bg) => {
                    return {
                        backgroundImage: 'url(' + url_bg + ')',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }
                })
            } else {
                let darker = lightenColor(marca.color_primario, -70);
                return {
                    background: `radial-gradient(${marca.color_primario}, ${darker})`
                }
            }
        }
        const loadImages = async () => {
            let func = fetchImages()
            let ___urls = func._urls;
            let index = func.index;
            await Promise.all(___urls).then((values) => {

                let _urls = {}
                for (let i in values) {
                    _urls[index[i]] = values[i];
                }
                setSeccionLogoUrls(_urls);


            });
            fetchBackground(marca).then((style) => {
                setDisplayStyle(style)

                setLoading(false);

            })
        }
        loadImages();
    }


    // end helpers logos secciones


    useEffect(() => {
        for (let st in displayStyle) {
            document.getElementById("display").style[st] = displayStyle[st];
        }
    }, [displayStyle])

    function orderSeccionesConIDS(data, ids) {
        let new_order = [];
        
        for (let id of ids) {

            if (accesoSecciones) {

                if (!accesoSecciones.includes(id)) {
                    continue
                }
    
            }

            if (id) {
    
                let index = data.findIndex(a => a.fs_id === id);
                new_order.push(data[index]);

            } else {

                new_order.push(false);

            }
        }

        return new_order;
    }

    function borrarSeccion(index_borrar) {
        let temp_data = [...editOrderSeccionesData];
        let temp_secciones_libres = [...seccionesLibres]
        temp_secciones_libres.push(temp_data[index_borrar])
        temp_data[index_borrar] = false;
        setSeccionesLibres(temp_secciones_libres)
        setEditOrderSeccionesData(temp_data)
    }

    function devolverSeccion(seccion_a_devolver, indexData) {
        let temp_secciones_libres = [...seccionesLibres];
        let temp_data = [...editOrderSeccionesData];
        console.log("devolver", seccion_a_devolver)
        console.log("libres", temp_secciones_libres)
        console.log("orden", temp_data)
        temp_data[indexData] = seccion_a_devolver;
        let index_a_liberar = temp_secciones_libres.findIndex((a) => a === seccion_a_devolver)
        temp_secciones_libres.splice(index_a_liberar, 1);
        setSeccionesLibres(temp_secciones_libres);
        setEditOrderSeccionesData(temp_data)
    }

    function addCuadraditoVacio(index) {
        let data_temp = [...editOrderSeccionesData]
        data_temp.splice(index, 0, false)
        setEditOrderSeccionesData(data_temp)
    }
    function borrarCuadradito(index) {
        let data_temp = [...editOrderSeccionesData]
        data_temp.splice(index, 1)
        setEditOrderSeccionesData(data_temp)
    }

    function SeccionComponent({ children, seccion, index, seccionesLibres, edit, style }) {
        if (!seccion) {
            if (!edit) {
                style = { background: "", border: "none", boxShadow: "none", backdropFilter: "none" }
            }
            return (
                <div key={index.toString()} className="singleBrand__section" style={style} >
                    <div className="wrapper">

                        {edit ? <select onChange={(e) => devolverSeccion(e.target.value, index)} style={{ width: "70px" }}>
                            <option value={false} key="vacio">Vacío</option>
                            {
                                seccionesLibres.map((s, i) => {
                                    console.log(s)
                                    console.log(secciones)
                                    let name = secciones[secciones.findIndex((secc) => secc.fs_id === s)].name;
                                    return (
                                        <option key={s} value={s}>{name}</option>
                                    )
                                })
                            }
                        </select>
                            : null}
                        {edit ? <button className="flex-bottom-right btn-addreporte-success btn-addreporte"
                            style={{ backgroundColor: "red", zIndex: 3 }}
                            title="Borrar" onClick={(e) => borrarCuadradito(index)}>Borrar
                        </button>
                            : null}
                    </div>
                </div>

            )
        }
        return (
            <div key={seccion.name} className="singleBrand__section" style={style} >
                {/* style={{ cursor: hasReports(seccion) ? "pointer" : "default" }}  */}
                <div className="wrapper" style={!edit ? { cursor: "pointer" } : null} onClick={!edit ? () => navigateConditional(seccion) : null}>
                    {/* <span className="singleBrand__section-title" style={{ fontWeight: "bold" }}>
                        {seccion.name}
                    </span> */}

                    {children}

                </div>

            </div>
        )
    }
    function Cuadradito_edit({ index }) {
        return (
            <div className="cuadradito_edit_wrapper" >
                <div className="cuadradito_edit btn-addreporte-success btn-addreporte" onClick={() => addCuadraditoVacio(index)}>
                    +
                </div>
            </div>
        )
    }
    function RenderMainComponentes({ dataOriginal, dataEdit, logos }) {
        if (!editOrderMode) {
            let new_data = orderSeccionesConIDS(dataOriginal, dataEdit);
            return new_data.map((seccion, index) => {
                return (<React.Fragment key={index}>

                    <SeccionComponent seccion={seccion} index={index} edit={false} style={{ background: "#fafafa" }}>
                        {
                            // si no tiene logo renderizar el nombre de la seccion
                            logos && logos[seccion.name] ?
                                (
                                    <img className="logo_seccion_img" src={logos[seccion.name]}
                                        title={seccion.name}>
                                    </img>
                                ) : (
                                    <span className="singleBrand__section-title" style={{ fontWeight: "bold" }}>
                                        {seccion.name}
                                    </span>
                                )
                        }

                        {
                            isWriter ?
                                <div className="botones_seccion">
                                    <button className="flex-bottom-right btn-addreporte-edit btn-addreporte" title="Editar sección" onClick={(e) => { seccionEdit(e, seccion) }}>✏️</button>
                                    <button className="flex-bottom-right btn-addreporte-success btn-addreporte" title="Añadir nuevo reporte" onClick={(e) => newReportMenu(e, seccion)}>+</button>
                                </div>
                                : null
                        }
                    </SeccionComponent>
                </React.Fragment>)
            })
        } else {
            let new_data = orderSeccionesConIDS(dataOriginal, dataEdit);
            // console.log(dataEdit)
            return (
                <>
                    {new_data.map((seccion, index) => (
                        <React.Fragment key={index}>
                            <Cuadradito_edit index={index} />
                            <SeccionComponent seccion={seccion} index={index} seccionesLibres={seccionesLibres} edit={true} style={{ background: "#fafafa", "height": "9.5rem", "width": "9.5rem", margin: "0px" }}>
                                {
                                    // si no tiene logo renderizar el nombre de la seccion
                                    logos && logos[seccion.name] ?
                                        (
                                            <img className="logo_seccion_img" src={logos[seccion.name]}
                                                title={seccion.name}>
                                            </img>
                                        ) : (
                                            <span className="singleBrand__section-title" style={{ fontWeight: "bold" }}>
                                                {seccion.name}
                                            </span>
                                        )
                                }
                                <div className="botones_seccion">
                                    <button className="flex-bottom-right btn-addreporte-success btn-addreporte"
                                        style={{ backgroundColor: "red", zIndex: 3 }}
                                        title="Borrar" onClick={(e) => borrarSeccion(index)}>x
                                    </button>
                                </div>
                            </SeccionComponent>
                        </React.Fragment>
                    ))
                    }
                    <Cuadradito_edit index={dataEdit.length} />
                </>)
        }
    }

    return (
        <>


            {!loading && accesoSeccionesLoaded ?
                <>

                    <div className="display" id="display" style={displayStyle}>
                        <div className="singleBrand centered_" style={{
                            background: `linear-gradient(340deg, ${brand.color_primario}, white)`
                        }} id="singleBrand">

                            {!editOrderMode ? <><h2 className="header_brand" >
                                {logoUrl ?
                                    <img src={logoUrl} alt={brand.name} width="250px" height="50px" fill="red"></img>
                                    : brand.name
                                }


                                {!isOnlyBrand ?
                                    <img alt="Home" onClick={() => navigate("/brands")}
                                        style={isWriter ? { marginLeft: "auto" } : null}
                                        width="20" height="20"
                                        src="/home_logo.png" className="home" />
                                    : null
                                }
                                {
                                    true ?
                                        <>
                                            <img alt="Perfil" onClick={() => navigate("/yo")}
                                                width="18" height="18"
                                                // style={isOnlyBrand && !isWriter ? { marginLeft: "auto" } : null}
                                                style={isOnlyBrand ? { marginLeft: "auto" } : null}
                                                src="/usuario.svg" className="home" />

                                            <img alt="Cerrar sesión" onClick={() => signtfOut()}
                                                width="20" height="20"
                                                // style={isOnlyBrand && !isWriter ? { marginLeft: "auto" } : null}
                                                src="/logout.png" className="home" />
                                        </>
                                        : null
                                }



                            </h2>
                            </>
                                : null}
                            <div className="singleBrand__section-conteiner">

                                <RenderMainComponentes dataOriginal={secciones} dataEdit={editOrderSeccionesData} logos={seccionLogoUrls} />


                            </div>



                            {
                                menuReport ?
                                    <div className="addNewSection">
                                        <form onSubmit={_addNewReport} className="addNewSection__form">
                                            <div className="addNewSection__form-div">
                                                <label htmlFor="reportName" >Nombre</label>
                                                <input type="text" style={{ background: `${brand.color_primario}80` }} name="reportName" value={newReportName}
                                                    onChange={(e) => setNewReportName(e.target.value)} />
                                            </div>
                                            <div className="addNewSection__form-div">
                                                <label htmlFor="dimensionPrimaria" >URL Data Studio</label>
                                                <input type="text" name="dimensionPrimaria" value={newUrlDataStudio}
                                                    onChange={(e) => setNewUrlDataStudio(e.target.value)} style={{ background: `${brand.color_primario}80` }} />
                                            </div>
                                            <div className="addNewSection__form-div">
                                                <label htmlFor="seccion" >Sección</label>
                                                <input type="text" name="seccion" value={newSeccion.name}
                                                    disabled style={{ background: `${brand.color_primario}80` }}></input>
                                            </div>

                                            <input type="submit"
                                                style={{ display: "none" }}></input>
                                            {/* <p style={{ cursor: "pointer" }} onClick={() => toggleMenuReport(false)}>Close</p> */}
                                            <div className="botones_añadir_seccion">
                                                <button className="botones_añadir_seccion_botones" onClick={() => toggleMenuReport(false)}>Cerrar</button>
                                                <button className="botones_añadir_seccion_botones" disabled={buttonDisabled} onClick={(e) => _addNewReport(e)}>Añadir reporte</button>
                                            </div>

                                        </form>

                                    </div>

                                    : null
                            }
                            {
                                menuSeccion ?
                                    <div className="addNewSection">
                                        {/* style={{ background: `${brand.color_primario}80` }}  */}
                                        <form id="form_seccion" onSubmit={_addNewSeccion} className="addNewSection__form">
                                            <div className="addNewSection__span">
                                                Añadir sección
                                            </div>

                                            <div className="addNewSection__form-div">

                                                <label htmlFor="SeccionName" >Nombre:</label>
                                                <input style={{ background: `${brand.color_primario}80` }} type="text" name="SeccionName" value={newSeccion.name}
                                                    onChange={(e) => setNewSeccion({ ...newSeccion, name: e.target.value })} />
                                            </div>
                                            <div className="addNewSection__form-div">
                                                <label htmlFor="seccionfoto" >Imagen:</label>
                                                <input style={{ background: `${brand.color_primario}80` }} type="file" name="seccionfoto" value={newSeccionPhoto}
                                                    onChange={(e) => addFile(e)}></input>
                                            </div>

                                            <input type="submit"
                                                style={{ display: "none" }}></input>
                                            <div className="botones_añadir_seccion">
                                                <button className="botones_añadir_seccion_botones" onClick={() => toggleMenuSeccion(false)}>Cerrar</button>
                                                <button className="botones_añadir_seccion_botones" disabled={buttonDisabled} onClick={(e) => _addNewSeccion(e)}>Añadir</button>
                                            </div>
                                        </form>

                                    </div>

                                    : null
                            }
                            {
                                editSeccion ?
                                    <div className="addNewSection">
                                        <form onSubmit={submitEditSeccion} className="addNewSection__form">
                                            <div className="addNewSection__span">
                                                Editar sección
                                            </div>

                                            <div className="addNewSection__form-div">

                                                <label htmlFor="SeccionName" >Nombre:</label>
                                                <input style={{ background: `${brand.color_primario}80` }} type="text" name="SeccionName" value={newSeccion.name}
                                                    onChange={(e) => setNewSeccion({ ...newSeccion, name: e.target.value })} />
                                            </div>
                                            <div className="addNewSection__form-div">
                                                <label htmlFor="seccionfoto" >Imagen:</label>
                                                <input style={{ background: `${brand.color_primario}80` }} type="file" name="seccionfoto" value=""
                                                    onChange={(e) => addFile(e)}></input>
                                            </div>

                                            <input type="submit"
                                                style={{ display: "none" }}></input>
                                            <div className="botones_añadir_seccion">
                                                <button className="botones_añadir_seccion_botones" onClick={() => toggleEditSeccion(false)}>Cerrar</button>
                                                <button className="botones_añadir_seccion_botones" disabled={buttonDisabled} onClick={(e) => submitEditSeccion(e)}>Guardar cambios</button>
                                            </div>

                                        </form>

                                    </div>

                                    : null
                            }

                            <div className="container_footer_brand">
                                {
                                    isWriter && !editOrderMode ?
                                        <div className="botones_bottom_singlebrand">
                                            <a className="addSection addSection__first" onClick={newSeccionMenu}>Añadir sección</a>
                                            <a className="addSection" onClick={() => toggleEditOrderMode(true)}>Editar orden</a>
                                            <a className="addSection" onClick={() => navigate("config_reportes")}>Editar reportes</a>
                                            <a className="addSection" onClick={() => navigate("/brand_config/" + id)}>Editar marca</a>
                                        </div>
                                        : <a style={{ color: "transparent", cursor: "default" }}>a</a>
                                }
                                {!editOrderMode ? <img title="Hecho con amor por los pibes de Data" alt="Hecho con amor por los pibes de Data"
                                    width="36" height="39" className="img_foooter_brand"
                                    src="/IsoNegro.svg" /> : <p style={{ color: "transparent" }}>a</p>}

                                {
                                    editOrderMode ? <>
                                        {/* <button onClick={() => toggleEditOrderMode(false)}>Cancelar</button> */}
                                        {/* <button onClick={() => saveEdit()}>Ok</button> */}
                                        <div className="botones_edit_secciones">
                                            <button className="botones_añadir_seccion_botones" onClick={() => toggleEditOrderMode(false)}>Cerrar</button>
                                            <button className="botones_añadir_seccion_botones" onClick={(e) => saveEdit()}>Guardar cambios</button>
                                        </div>

                                    </> : null
                                }

                            </div>


                        </div>
                    </div>
                </>
                : <Spinner />
            }

        </>
    )
}
