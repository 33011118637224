import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFirebaseApp } from "reactfire"
import { firebaseConfig } from '../etc/Firebase'
import { doc, getDoc, getDocs, getFirestore, updateDoc, deleteDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { UploadFileToStorage } from "../etc/CreativeHelpers";
import { GetCreativeUrls } from "../etc/CreativeHelpers";

export default function SingleBrandConfig() {
    const navigate = useNavigate();
    const firebase = useFirebaseApp(firebaseConfig)
    const db = getFirestore(firebase);

    let params = useParams();
    let id = params.id;

    let [brand, setBrand] = useState(null);
    let [brandName, setBrandName] = useState(null);
    let [brandColor, setBrandColor] = useState(null);
    let [logoId, setLogoId] = useState("");
    let [logoUrl, setLogoUrl] = useState("");
    let [logoFilename, setLogoFileName] = useState("");
    let [loading, setLoading] = useState(true);
    let [BGFileName, setBGFileName] = useState("");
    let [BGId, setBGId] = useState("");
    let [gradiente, setGradiente] = useState(false);

    function updateBrandConfig(e) {
        e.preventDefault();
        let _brand = { ...brand };
        _brand.name = brandName;
        _brand.color_primario = brandColor;
        _brand.logo_id = logoId || "";
        _brand.gradiente = gradiente;
        _brand.bg_image = BGId;
        updateBrandInFS(_brand);
    }

    async function updateBrandInFS(data) {
        await updateDoc(doc(db, "report", id), data);
        updateData();
    }

    function addFileLogo(e) {
        setLogoFileName(e.target.value)
        let id = UploadFileToStorage(e.target.files[0]);
        setLogoId(id);
    }

    function addFileBackground(e) {
        setBGFileName(e.target.value)
        let id = UploadFileToStorage(e.target.files[0]);
        setBGId(id);
    }

    function borrarMarca() {
        let a = prompt("Estas seguro? Para borrar escribi DELETE")
        if (a !== "DELETE") return
        deleteDoc(doc(db, "report", id)).then(() => {
            navigate(-2);
        })

    }

    async function getData() {
        const docRef = doc(db, "report", id);
        const docSnap = await getDoc(docRef);
        return docSnap.data()
    }

    const fetchLogos = async (logo_id) => {
        const _urls = logo_id ? await GetCreativeUrls(logo_id) : null;
        return _urls
    }

    function updateData() {
        getData().then((data) => {
            setBrand(data);
            setBrandName(data.name);
            setBrandColor(data.color_primario || "#969696");
            setLogoId(data.logo_id || "");
            setBGId(data.bg_image || "");
            setGradiente("gradiente" in data ? data.gradiente : false);

            fetchLogos(data.logo_id).then((_urls)=>{
                setLogoUrl(_urls);
                setLoading(false);
            })

        })
    }
    useEffect(() => {
        updateData()
    }, [])
    
    return brand ? (
        <><div className="display">
        <div className="brandConfig">
            <button onClick={() => navigate(-1)} className="goBack">Back</button>
            <h2>{brand.name}</h2>
            <h3>Configuración:</h3>
            <form onSubmit={updateBrandConfig}>
                <div className="addNewSection__form-div">
                <label htmlFor="color">Color Primario</label>
                <input type="color" name="color" value={brandColor}
                    onChange={(e) => setBrandColor(e.target.value)}></input>
                </div>
                <div className="addNewSection__form-div">
                <label htmlFor="logo">Logo (250x50px, png o jpg)</label>
                <input type="file" name="filelogo"
                    onChange={(e) => addFileLogo(e)}></input>
                {/* <img src={logoUrl} ></img> */}
                </div>

                <div className="addNewSection__form-div">
                <label htmlFor="bg">Imagen de fondo</label>
                <input type="file" name="bg"
                    onChange={(e) => addFileBackground(e)}></input>
                </div>
                <div className="addNewSection__form-div">
                <label htmlFor="gradiente">Gradiente</label>
                <input type="checkbox" name="gradiente" onChange={()=>setGradiente(!gradiente)} checked={gradiente}></input>
                {/* <img src={logoUrl} ></img> */}
                </div>


                
                


                <input type="submit" className="addSection" value="Guardar"></input>
            </form>
            <button onClick={borrarMarca} className="addSection">Borrar marca</button>
            </div>
            </div>
        </>
    ) : null
}