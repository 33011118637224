import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { FirebaseAppProvider } from 'reactfire';
import { ThemeProvider } from './Context/ThemeContext';
import { firebaseConfig } from "./etc/Firebase"
import IsLoggedIn from './etc/IsLoggedIn';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <ThemeProvider>
        <IsLoggedIn />
        </ThemeProvider>
    </FirebaseAppProvider>
  // </React.StrictMode>
);
